import { UnlockMessageData } from "@/components/messaging/types"
import {
  ChannelResponse,
  CommandVariants,
  Event,
  MessageBase,
  Reaction,
  StreamChat,
  User,
} from "stream-chat"
import {
  DefaultAttachmentType,
  DefaultStreamChatGenerics,
} from "stream-chat-react/dist/types/types"

export type StreamUser = User & {
  image?: string
  team: {
    id: number
    name: string
    image?: string
  }
}

export interface StreamTypes extends DefaultStreamChatGenerics {
  attachmentType: DefaultAttachmentType
  channelType: ChannelResponse & {
    from_profile: { hero: string; name: string; slug: string }
    from_profile_slug: string
    to_profile_slugs: string[]
    to_project?: {
      hero?: string
      name: string
      slug: string
      parent_profile_slugs: string[]
    }
    to_profile?: {
      hero: string
      name: string
      slug: string
    }
  }
  commandType: CommandVariants
  eventType: Event
  messageType: MessageBase | UnlockMessageData
  reactionType: Reaction
  userType: StreamUser
}

export const client = StreamChat.getInstance<StreamTypes>(
  process.env.NEXT_PUBLIC_STREAM_KEY
)

export default client
