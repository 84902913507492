"use client"

import { Chat, Streami18n } from "stream-chat-react"
import client, { StreamTypes } from "@/lib/chatClient"
import { FC, ReactNode } from "react"

const i18nInstance = new Streami18n({
  language: "en",
  translationsForLanguage: {
    "You have no channels currently":
      "You have no new messages. You can start a thread by messaging or expressing interest in a profile or project.",
    "Drag your files here": "Drag your files here. Max size 100MB",
  },
})

export const ChatContextProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <Chat<StreamTypes>
      customClasses={{
        messageList: "str-chat__list message-list-scroller",
        // virtualizedMessageList: "flex-grow flex-col",
        // chat: "flex",
        // channel: "flex-grow",
        // chatContainer: "bg-white",
      }}
      client={client}
      i18nInstance={i18nInstance}
    >
      {children}
    </Chat>
  )
}
export default ChatContextProvider
