"use client"

import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react"
import Cookies from "js-cookie"
import { configureScope, setUser as setSentryUser } from "@sentry/nextjs"
import { useChatContext } from "stream-chat-react"

import {
  API,
  API_URL,
  AUTH_COOKIE_PATH,
  CSRF_COOKIE_PATH,
  MASQUERADE_SLUG_COOKIE_PATH,
  MASQUERADE_TYPE_COOKIE_PATH,
  SESSION_COOKIE_PATH,
} from "lib/api"

import type { User } from "types/User"
import type { Artist } from "types/Artist"
import type { Brand } from "types/Brand"
import type { ArtistOrBrand } from "types/ArtistOrBrand"
import type { TeamMember } from "@/types/TeamMember"
import { useAppDispatch, useAppSelector } from "@/store/store"
import { authSelectors } from "@/store/auth/selectors"
import { authActions } from "@/store/auth/authSlice"
import { stripAuthCookies } from "@/lib/serverActions"

function cleanUrl(url) {
  return url.replace(/^https?:\/\//, "").replace(/\/+$/, "")
}

const ctxSetState: Dispatch<SetStateAction<User>> = (s) => s

type Masquerade = Partial<ArtistOrBrand> & { active_users?: TeamMember[] }

export const AuthContext = createContext({
  /* eslint-disable @typescript-eslint/no-unused-vars */
  user: null as User,
  setUser: ctxSetState,
  login: async (form) =>
    ({}) as {
      user: User
      ott?: string
      type?: "login" | "QR"
      user_id?: number
    },
  getUser: async () => ({}) as User,
  loginMFA: async (token) => null,
  signup: async (form) => ({}),
  loginWithToken: async (token) => ({}) as User,
  logout: async () => {},
  setMasquerade: (type: "artist" | "brand", entity: Artist | Brand) => {},
  clearMasquerade: (clearState?: boolean) => {},
  masquerade: null as Masquerade,
  // masqueradeHistory: [] as ArtistOrBrand[],
  setDraft: (draft: any) => {},
  draft: null as any,
  /* eslint-enable @typescript-eslint/no-unused-vars */
})

export const AuthContextProvider: FC<{
  children?: ReactNode
}> = ({ children }) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(authSelectors.user)
  const masquerade = useAppSelector(authSelectors.masquerade)
  const { client } = useChatContext()
  const [draft, setDraft] = useState<any>()

  // const [hasAuthToken, setHasAuthToken] = useState(
  //   !!Cookies.get(AUTH_COOKIE_PATH)
  // )

  const msqSlug = Cookies.get(MASQUERADE_SLUG_COOKIE_PATH)
  const msqType = Cookies.get(MASQUERADE_TYPE_COOKIE_PATH)

  useEffect(() => {
    if (!msqSlug || !msqType) {
      dispatch(authActions.setMasquerade(undefined))
    }
  }, [dispatch, msqSlug, msqType])

  useEffect(() => {
    if (typeof window === "undefined") {
      return
    }
    if (!user) {
      configureScope((scope) => scope.setUser(null))
      return
    }
    setSentryUser({
      email: user?.email,
      username: user?.email,
    })
    const team = user?.teams?.[0]
    if (user?.stream_token && !client?.user?.id) {
      client?.connectUser(
        {
          id: user?.stream_id,
          name: [user?.first_name, user?.last_name].join(" "),
          image: user?.profile_image,
          team: team
            ? { id: team?.id, name: team?.name, image: team?.team_image }
            : null,
        },
        user?.stream_token
      )
    }
    // Populate GTM data layer
    window?.dataLayer?.push({ user_email: user?.email })
    if (team) {
      window?.dataLayer?.push({ user_team_name: team?.name })
    }
  }, [client, user])

  const setUser = useCallback(
    (newUser: User | ((prev: User) => void)) => {
      if (typeof newUser === "function") {
        dispatch(authActions.setUser(newUser(user as User)))
      } else {
        dispatch(authActions.setUser(newUser))
      }
    },
    [dispatch, user]
  )

  const setMasquerade = useCallback(
    async (type: "artist" | "brand", entity: Artist | Brand) => {
      if (!entity?.slug) {
        return
      }
      API.setMasquerade(type, entity.slug)
      Cookies.set(MASQUERADE_SLUG_COOKIE_PATH, entity.slug, {
        sameSite: "lax",
        expires: 90,
      })
      Cookies.set(MASQUERADE_TYPE_COOKIE_PATH, type, {
        sameSite: "lax",
        expires: 90,
      })
      const newMasquerade = { type, ...entity } as ArtistOrBrand
      dispatch(authActions.setMasquerade(newMasquerade))
    },
    [dispatch]
  )

  const clearMasquerade = useCallback(
    (clearState = true) => {
      // delete cookie for client-side
      Cookies.remove(MASQUERADE_SLUG_COOKIE_PATH)
      Cookies.remove(MASQUERADE_TYPE_COOKIE_PATH)
      API.stripMasquerade()
      if (!clearState) {
        return
      }
      dispatch(authActions.setMasquerade(undefined))
    },
    [dispatch]
  )

  useEffect(() => {
    if (!Cookies.get(AUTH_COOKIE_PATH) && !Cookies.get(CSRF_COOKIE_PATH)) {
      return () => {}
    }
    const validateToken = async (options?: any) => {
      const { data } = await API.get("/api/check_auth_status/", options)
      return data
    }
    const abortController = new AbortController()
    const validate = () =>
      validateToken({ signal: abortController.signal })
        .then((loggedIn) => {
          if (!loggedIn) {
            throw new Error("User is not logged in")
          }
        })
        .catch(async () => {
          if (abortController.signal.aborted) {
            return
          }
          try {
            await stripAuthCookies()
          } catch (e) {
            console.log(e)
          }
          Cookies.remove(AUTH_COOKIE_PATH)
          API.stripToken()
          API.stripSession()
          clearMasquerade()
          localStorage.setItem("login_status", Date.now().toString())
          window.location.replace("/login")
        })

    if (typeof window !== "undefined" && "requestIdleCallback" in window) {
      window.requestIdleCallback(validate)
    } else {
      validate()
    }

    return () => {
      abortController.abort()
    }
  }, [clearMasquerade, setMasquerade, dispatch])

  const loginWithToken = useCallback(
    async (key) => {
      API.setToken(key)
      Cookies.set(AUTH_COOKIE_PATH, key, { sameSite: "lax", expires: 90 })
      const { data } = await API.get("/api/user/")
      dispatch(authActions.setUser(data as User))
      return data as User
    },
    [dispatch]
  )

  const login = useCallback(async (form) => {
    try {
      API.stripToken()
      API.stripSession()
      API.stripMasquerade()
      const currentOrigin = cleanUrl(window.location.origin)
      const apiUrl = cleanUrl(API_URL)
      let sameSiteTest: boolean = false
      if (apiUrl === currentOrigin) {
        sameSiteTest = true
      }

      const response = await API.post<{
        key: string
        user: User
        ott?: string
        type?: "login" | "QR"
        user_id?: number
      }>("/rest-auth/login/", form)
      const { key, user: newUser, type, user_id, ott } = response.data
      const csrftoken = Cookies.get("csrftoken")
      if (sameSiteTest) {
        if (csrftoken) {
          API.setXCSRFToken(csrftoken)
        }
      } else if (key) {
        Cookies.set(AUTH_COOKIE_PATH, key, { sameSite: "lax", expires: 90 })
        API.setToken(key)
      }
      return { user: newUser, type, user_id, ott }
    } catch (error) {
      if (error.response && error.response.status === 403) {
        console.error("An expected error occurred:", error)
      } else {
        console.error("An unexpected error occurred:", error)
      }
      localStorage.setItem("login_status", Date.now().toString())
      throw error
    }
  }, [])

  const getUser = useCallback(async () => {
    const { data } = await API.get("/api/user/")
    dispatch(authActions.setUser(data as User))
    localStorage.setItem("login_status", Date.now().toString())
    return data as User
  }, [dispatch])

  const loginMFA = useCallback(async (token) => {
    await API.post(`/api/totp/login/${token}/`)
    // TODO: Update user data
  }, [])

  const signup = useCallback(
    async (form) => {
      const {
        data: { key },
      } = await API.post("/rest-auth/signup/", form)
      const usr = await loginWithToken(key)
      return usr
    },
    [loginWithToken]
  )

  const logout = useCallback(async () => {
    const csrf_token = Cookies.get("csrftoken")
    if (csrf_token) {
      console.log("Logging out - Protocol1")
      try {
        await API.post("/rest-auth/logout/")
      } catch (e) {
        console.warn("Error logging out", e)
      }
    } else {
      console.log("Logging out - Protocol2")
    }
    Cookies.remove(AUTH_COOKIE_PATH)
    Cookies.remove(CSRF_COOKIE_PATH)
    Cookies.remove(SESSION_COOKIE_PATH)
    // setHasAuthToken(false)
    API.stripToken()
    clearMasquerade()
    // Wait until the new page has loaded before unsetting the user in case the page relies on user data
    // function resetUser(this: any) {
    //   setUser(null)
    //   // router.events.off("routeChangeComplete", this)
    // }

    // router.events.on("routeChangeComplete", resetUser)
    localStorage.setItem("login_status", Date.now().toString())
    window.location.hash = ""
    window.location.reload()
  }, [clearMasquerade])

  const fields = useMemo(
    () => ({
      user,
      setUser,
      login,
      getUser,
      loginMFA,
      signup,
      logout,
      loginWithToken,
      masquerade,
      setMasquerade,
      clearMasquerade,
      draft,
      setDraft,
    }),
    [
      user,
      setUser,
      login,
      getUser,
      loginMFA,
      signup,
      logout,
      loginWithToken,
      masquerade,
      setMasquerade,
      clearMasquerade,
      draft,
      setDraft,
    ]
  )

  return <AuthContext.Provider value={fields}>{children}</AuthContext.Provider>
}

export default AuthContext
